<template>
  <CBox>
    <CBox bg="bgMain" h="100%" minHeight="100vh">
      <router-view />
    </CBox>
  </CBox>
</template>

<script>
import { CBox } from "@chakra-ui/vue";

export default {
  components: {
    CBox,
  },
};
</script>
